* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: 'Avenir';
  scroll-behavior: smooth;

  -webkit-overflow-scrolling: touch;

  @media screen and (max-width: 800px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
}

@font-face {
  font-family: 'HarmonyOS Sans SC';
  src: url('./assets/fonts/Avenir-Roman.ttf') format('Avenir');
  font-weight: normal;
}

@font-face {
  font-family: 'NexaBlodItalic';
  src: url('./assets/fonts/Nexa-XBold-Italic.otf');
}

@font-face {
  font-family: 'NexaBlod';
  src: url('./assets/fonts/Nexa-XBold.otf');
}

@font-face {
  font-family: 'NexaBlack';
  src: url('./assets/fonts/NexaBlack.otf');
}

@font-face {
  font-family: 'NexaHeavy';
  src: url('./assets/fonts/NexaHeavy.otf');
}

@font-face {
  font-family: 'RRocgrotesk';
  src: url('./assets/fonts/Fontspring-DEMO-rocgrotesk-regular.otf');
}

@font-face {
  font-family: 'BRocgrotesk';
  src: url('./assets/fonts/Fontspring-DEMO-rocgrotesk-bold.otf');
}

.hover-animate {
  transform: scale(1);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}
