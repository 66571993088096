.home {
  width: 100vw;
  min-height: 100vh;
  position: relative;

  &-background {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  &-main {
    position: relative;
    z-index: 1;
    width: 8.78rem;
    height: 4.79rem;
    // overflow: hidden;
    margin: 0 auto;
    // background-image: url('../../assets/images/background_home_1.png');
    // background-size: 13.27rem 7.51rem;
    // background-position: -0.2rem -0.15rem;
    position: relative;

    &-contents {
      position: absolute;
      left: 0;
      top: 2.33rem;
      width: 5.99rem;
      z-index: 1;

      &-slogen {
        font-size: 0.36rem;
        line-height: 0.576rem;
        color: #000000;
        font-family: 'NexaHeavy';

        &.small {
          font-size: 0.28rem;
          line-height: 0.448rem;
        }
      }

      &-sub {
        font-size: 0.2rem;
        line-height: 0.27rem;
        color: rgba(0, 0, 0, 0.7);
        margin-bottom: 0.12rem;
        font-weight: 400;
        font-style: italic;
      }

      a {
        text-decoration: none;
      }

      &-btn {
        width: 1.83rem;
        height: 0.55rem;
        border-radius: 0.65rem;
        background-color: rgba(0, 12, 0, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.16rem;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
        font-family: 'BRocgrotesk';
        text-decoration: none;
        margin-top: 0.55rem;

        img {
          width: 0.16rem;
          height: 0.16rem;
          margin-right: 0.08rem;
        }
      }
    }

    &-ball {
      width: 6.1rem;
      height: 8.13rem;
      display: block;
      position: absolute;
      left: 3.94rem;
      top: 0.24rem;
    }
  }

  &-video {
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    // background-image: url('../../assets/images/background_home_2.png');
    // background-size: 16.0134rem 6.32rem;
    // background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.3rem;
    margin-bottom: 1.2rem;

    &-wrap {
      width: 8.81rem;
      height: 4.95rem;
      border-radius: 0.32rem;
      background-color: #ffffff;
      overflow: hidden;
    }
  }

  &-features {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 6.06rem;
    overflow: hidden;
    // background-image: url('../../assets/images/background_home_3.png');
    // background-size: 13.27rem 9.36rem;
    // background-position: -0.2rem -0.94rem;

    &-title {
      display: block;
      font-size: 0.4rem;
      line-height: 0.6rem;
      text-align: center;
      font-family: 'NexaHeavy';
      color: #000000;
      margin-bottom: 0.32rem;
    }

    &-wrap {
      width: 8.78rem;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;

      &-item {
        width: 2.77rem;
        height: 4.23rem;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 0.2rem;
        padding: 0.12rem 0 0 0.2rem;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);

        &-icon {
          width: 0.8rem;
          height: 0.8rem;
          display: block;
          margin-bottom: 0.15rem;
        }

        &-title {
          font-size: 0.16rem;
          line-height: 0.19rem;
          color: #000000;
          font-weight: 900;
          margin-bottom: 0.08rem;
          padding-right: 0.12rem;
        }

        &-desc {
          font-weight: 400;
          font-size: 0.14rem;
          line-height: 0.19rem;
          color: rgba(0, 0, 0, 0.6);
          padding-right: 0.2rem;
        }
      }
    }
  }
}
