.mobile-app {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  &-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-wrap {
    width: 100vw;
    position: relative;
    z-index: 1;
    padding-top: 1.51rem;
    overflow: hidden;

    &-notify {
      overflow: hidden;

      &-title {
        font-size: 0.2rem;
        line-height: 0.32rem;
        color: #000;
        font-family: 'NexaHeavy';
        margin-bottom: 0.22rem;
        text-align: center;
        padding: 0 0.52rem;
      }

      &-sub {
        font-size: 0.16rem;
        line-height: 0.22rem;
        color: rgba(0, 0, 0, 0.7);
        font-weight: 400;
        margin-bottom: 0.6rem;
        text-align: center;
        padding: 0 0.52rem;

        span {
          font-weight: 800;
          font-style: italic;
        }
      }

      &-input {
        margin: 0 auto 1.98rem;
        width: 3.2rem;
        height: 0.64rem;
        border-radius: 0.32rem;
        background-color: rgba(255, 255, 255, 0.2);
        border: 0.0082rem solid rgba(0, 0, 0, 0.1);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        position: relative;

        input {
          width: 100%;
          height: 100%;
          padding-left: 0.28rem;
          padding-right: 1.38rem;
          font-size: 0.16rem;
          line-height: 0.23rem;
          outline: none;
          border: none;
          background-color: transparent;
          display: block;
        }

        &-btn {
          position: absolute;
          right: 0.09rem;
          top: 0.05rem;
          width: 1.29rem;
          height: 0.54rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.15rem;
          color: #fff;
          font-family: 'NexaHeavy';
          cursor: pointer;
          background-color: rgba(0, 12, 0, 0.9);
          border-radius: 0.65rem;
        }
      }
    }

    &-roadmap {
      margin-bottom: 1.4rem;

      &-title {
        padding-left: 0.49rem;
        font-size: 0.2rem;
        line-height: 0.32rem;
        font-family: 'NexaHeavy';
        margin-bottom: 0.43rem;
      }

      &-container {
        padding-left: 0.55rem;

        &-item {
          margin-bottom: 0.48rem;

          &-no {
            width: 0.44rem;
            height: 0.44rem;
            border-radius: 0.08rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.16rem;
            color: rgba(249, 249, 247, 1);
            font-family: 'NexaHeavy';
            margin-bottom: 0.2rem;
            background-color: #000;
          }

          &-content {
            display: flex;

            &-line {
              width: 0.01rem;
              height: 1.59rem;
              background-color: #000;
              margin-left: 0.22rem;
              margin-right: 0.4rem;
            }

            &-right {
              &-date {
                height: 0.27rem;
                display: flex;
                align-items: center;
                font-size: 0.18rem;
                font-weight: 900;
                line-height: 0.25rem;
                margin-bottom: 0.13rem;

                &.coming {
                  .mobile-app-wrap-roadmap-container-item-content-right-date-tag {
                    background-color: rgba(199, 201, 95, 0.17);
                    color: rgba(153, 155, 32, 1);
                  }

                  .mobile-app-wrap-roadmap-container-item-content-right-date-tag-dot {
                    background-color: rgba(153, 155, 32, 1);
                  }
                }

                &-tag {
                  width: 0.8rem;
                  height: 0.27rem;
                  border-radius: 0.153rem;
                  background-color: rgba(110, 202, 100, 0.17);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 0.09rem;
                  color: rgba(73, 204, 58, 1);
                  margin-left: 0.1rem;

                  &-dot {
                    width: 0.0459rem;
                    height: 0.0459rem;
                    border-radius: 100%;
                    margin-right: 0.0306rem;
                    background-color: rgba(73, 204, 58, 1);
                  }
                }
              }

              &-title {
                font-size: 0.14rem;
                line-height: 0.19rem;
                color: #000;
                font-weight: 800;
                margin-bottom: 0.13rem;
              }

              &-desc {
                font-size: 0.14rem;
                line-height: 0.25rem;
                color: #000;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}
