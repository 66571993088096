.mobile-header {
  width: 3.5rem;
  height: 0.39rem;
  border-radius: 0.51rem;
  background-color: rgba(255, 255, 255, 0.38);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: fixed;
  left: 50%;
  top: 0.34rem;
  transform: translateX(-50%);
  padding-left: 0.24rem;
  padding-right: 0.06rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;

  &-menu {
    display: block;
    width: 16px;
    height: 0.16rem;
  }

  &-close {
    display: block;
    width: 16px;
    height: 0.16rem;
  }

  &-btn {
    width: 0.88rem;
    height: 0.31rem;
    border-radius: 0.51rem;
    background-color: rgba(23, 34, 25, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.1rem;
    color: #fff;
    font-family: 'NexaBlod';
  }
}

.menu-wrap {
  width: 100vw;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
  background-color: rgba(230, 238, 254, 1);
  z-index: 999;
  overflow: hidden;

  &.active {
    height: 100vh;
    z-index: 999;
  }

  &-nav {
    padding-top: 1.13rem;
    padding-left: 0.36rem;

    a {
      text-decoration: none;
      margin-bottom: 0.26rem;
      display: block;
    }

    &-item {
      font-size: 0.14rem;
      line-height: 0.21rem;
      color: #000;
      font-family: 'NexaBlod';
    }
  }
}
