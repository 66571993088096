.app-page {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  &-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-wrap {
    position: relative;
    z-index: 1;
    // padding-top: 1.93rem;
  }

  &-notify {
    padding-top: 1.93rem;
    overflow: hidden;

    &-title {
      font-size: 0.36rem;
      line-height: 0.58rem;
      color: #000;
      font-family: 'NexaHeavy';
      margin-bottom: 0.08rem;
      text-align: center;
    }

    &-sub {
      font-size: 0.22rem;
      line-height: 0.3rem;
      color: rgba(0, 0, 0, 0.7);
      font-weight: 400;
      margin-bottom: 0.55rem;
      text-align: center;

      span {
        font-weight: 800;
        font-style: italic;
      }
    }

    &-input {
      margin: 0 auto 1.98rem;
      width: 5.82rem;
      height: 0.64rem;
      border-radius: 0.32rem;
      background-color: rgba(255, 255, 255, 0.2);
      border: 0.01rem solid rgba(0, 0, 0, 0.1);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      position: relative;

      input {
        width: 100%;
        height: 100%;
        padding-left: 0.28rem;
        padding-right: 1.38rem;
        font-size: 0.16rem;
        line-height: 0.23rem;
        outline: none;
        border: none;
        background-color: transparent;
        display: block;
      }

      &-btn {
        position: absolute;
        right: 0.09rem;
        top: 0.05rem;
        width: 1.29rem;
        height: 0.54rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.15rem;
        color: #fff;
        font-family: 'NexaHeavy';
        cursor: pointer;
        background-color: rgba(0, 12, 0, 0.9);
        border-radius: 0.65rem;
      }
    }
  }

  &-roadmap {
    display: flex;
    padding-top: 0.3rem;
    margin-bottom: 0.55rem;

    .mr30 {
      margin-left: 0.3rem;
    }

    .mr112 {
      margin-right: 1.12rem;
    }

    .mr137 {
      margin-right: 1.37rem;
    }

    .ml160 {
      margin-left: 1.6rem;
    }

    &-title {
      font-size: 0.4rem;
      line-height: 0.6rem;
      color: #000;
      font-family: 'NexaHeavy';
      margin-left: 2rem;
      margin-right: 0.6rem;
    }

    &-container {
      flex: 1;
      margin-right: 1.27rem;

      &-top {
        display: flex;
        margin-bottom: 0.48rem;
      }

      &-line {
        width: 100%;
        height: 0.003rem;
        background-color: #000;
        margin: 0.28rem 0;
      }

      &-bottom {
        display: flex;
      }

      &-item {
        width: 2.15rem;
        margin-right: 0.3rem;

        &.last {
          width: 100%;
          flex: 1;
          margin-right: 0;
        }

        &-no {
          display: flex;
          align-items: center;
          margin-bottom: 0.19rem;

          &-text {
            width: 0.44rem;
            height: 0.44rem;
            border-radius: 0.08rem;
            background-color: #000;
            font-size: 0.18rem;
            line-height: 0.24rem;
            font-family: 'NexaHeavy';
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0.1rem;
            color: #fff;
          }

          &-line {
            flex: 1;
            height: 0.003rem;
            background-color: #000;
          }
        }

        &-date {
          height: 0.27rem;
          display: flex;
          align-items: center;
          font-size: 0.18rem;
          font-weight: 900;
          line-height: 0.25rem;
          margin-bottom: 0.13rem;

          &.coming {
            .app-page-roadmap-container-item-date-tag {
              background-color: rgba(199, 201, 95, 0.17);
              color: rgba(153, 155, 32, 1);
            }

            .app-page-roadmap-container-item-date-tag-dot {
              background-color: rgba(153, 155, 32, 1);
            }
          }

          &-tag {
            width: 0.8rem;
            height: 0.27rem;
            border-radius: 0.153rem;
            background-color: rgba(110, 202, 100, 0.17);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.09rem;
            color: rgba(73, 204, 58, 1);
            margin-left: 0.1rem;

            &-dot {
              width: 0.0459rem;
              height: 0.0459rem;
              border-radius: 100%;
              margin-right: 0.0306rem;
              background-color: rgba(73, 204, 58, 1);
            }
          }
        }

        &-title {
          font-size: 0.14rem;
          line-height: 0.19rem;
          color: #000;
          font-weight: 800;
          margin-bottom: 0.13rem;
        }

        &-desc {
          font-size: 0.14rem;
          line-height: 0.25rem;
          color: #000;
          font-weight: 400;
        }
      }
    }
  }

  /* 蒙版层样式 */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
    z-index: 9999; /* 设置层级，确保在最顶层显示 */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

::-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #172219;
  opacity: 0.5;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #172219;
  opacity: 0.5;
}
input::-webkit-input-placeholder {
  color: #172219;
  opacity: 0.5;
}
