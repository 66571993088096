.join-waitlist {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  &-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-wrap {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    width: 5rem;
    padding: 1.48rem 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-title {
    font-size: 0.32rem;
    line-height: 0.46rem;
    font-family: 'NexaHeavy';
    margin-bottom: 0.06rem;
  }

  &-sub {
    font-size: 0.16rem;
    line-height: 0.23rem;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
    margin-bottom: 0.24rem;
    text-align: center;
  }

  &-input {
    width: 4.02rem;
    height: 0.58rem;
    border-radius: 0.2rem;
    border: 0.01rem solid rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.2);
    outline-style: none;
    font-weight: 400;
    font-size: 0.16rem;
    line-height: 0.23rem;
    padding: 0 0.25rem;
    box-sizing: border-box;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    margin-bottom: 0.14rem;

    &:nth-child(5) {
      height: 1.06rem;
      padding-top: 0.18rem;
      padding-bottom: 0.18rem;
      resize: none;
      overflow: hidden;
      margin-bottom: 0.3rem;
    }
  }

  &-btn {
    width: 4.11rem;
    height: 0.54rem;
    border-radius: 0.65rem;
    background-color: rgba(0, 12, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 0.15rem;
    color: #fff;
    font-family: 'NexaHeavy';
  }

  /* 蒙版层样式 */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
    z-index: 9999; /* 设置层级，确保在最顶层显示 */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

::-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #172219;
  opacity: 0.5;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #172219;
  opacity: 0.5;
}
input::-webkit-input-placeholder {
  color: #172219;
  opacity: 0.5;
}
