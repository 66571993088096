.calendly-wrap {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  &-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-container {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding-top: 1.48rem;
  }
}
