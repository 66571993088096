.footer {
  width: 100%;
  height: 1.24rem;
  padding: 0 0.32rem;
  position: relative;
  z-index: 1;

  &-wrap {
    width: 100%;
    height: 100%;
    border-top: 0.01rem solid #b4b4b4;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  img {
    display: block;
    width: 1.43rem;
    height: 0.27rem;
  }

  &-rights {
    flex: 1;
    font-size: 0.12rem;
    color: #000;
  }

  &-links {
    flex: 1;
    margin-right: 0.16rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      font-size: 0.12rem;
      color: #000;
      text-decoration: none;
    }

    &-policy {
      margin-right: 0.76rem;
    }
  }
}
