.mobile-cases {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  &-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  &-wrap {
    position: relative;
    z-index: 1;
    width: 100vw;
    padding-top: 1.21rem;
  }

  &-title {
    font-size: 0.2rem;
    line-height: 0.32rem;
    margin-bottom: 0.09rem;
    color: #000;
    font-family: 'NexaHeavy';
    text-align: center;
  }

  &-sub {
    font-size: 0.16rem;
    line-height: 0.22rem;
    margin-bottom: 0.44rem;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
    text-align: center;
    padding: 0 0.52rem;
  }

  &-infos {
    ::-webkit-scrollbar {
      display: none;
    }

    width: 100%;
    height: 3.42rem;
    overflow: hidden;

    &-wrap {
      height: 3.42rem;
      white-space: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
    }

    &-item {
      width: 2.81rem;
      height: 3.42rem;
      // background-image: url('../../assets/images/cases_container.png');
      // background-size: 100%;
      // background-position: top left;
      // background-repeat: no-repeat;
      position: relative;
      padding: 0.28rem 0.23rem 0;
      border-radius: 0.2rem;
      background-color: rgba(255, 255, 255, 0.2);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      display: inline-block;
      margin-right: 0.2rem;
      overflow: hidden;
      white-space: normal;

      &:first-child {
        margin-left: 0.43rem;
      }

      &:last-child {
        margin-right: 0.43rem;
      }

      &-no {
        position: absolute;
        top: 0;
        right: -0.037rem;
        width: 0.5175rem;
        height: 0.5175rem;
        border-radius: 100%;
        background-color: #121212;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.1922rem;
        color: #fff;
        font-family: 'RRocgrotesk';
      }

      &-dot {
        width: 0.54rem;
        height: 0.54rem;
        border-radius: 100%;
        background-color: rgba(119, 173, 255, 0.19);
        margin-bottom: 0.28rem;
      }

      &-title {
        font-size: 0.19rem;
        line-height: 0.26rem;
        color: #000000;
        font-weight: 900;
      }

      &-sub {
        font-size: 0.16rem;
        line-height: 0.21rem;
        color: #000000;
        font-weight: 900;
        margin-bottom: 0.15rem;
      }

      &-content {
        font-weight: 400;
        font-size: 0.15rem;
        line-height: 0.2rem;
        color: rgba(0, 0, 0, 0.7);
        white-space: normal;
      }
    }
  }
}
