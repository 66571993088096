.mobile-home {
  width: 100vw;
  min-height: 100vh;
  position: relative;

  &-background {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  &-main {
    position: relative;
    z-index: 1;
    width: 100%;
    padding-top: 1.5rem;

    &-sub {
      font-size: 0.15rem;
      line-height: 0.225rem;
      font-weight: 400;
      margin-bottom: 0.03rem;
      text-align: center;
      color: #000;
    }

    &-title {
      font-size: 0.26rem;
      font-family: 'NexaHeavy';
      // line-height: 0.416rem;
      margin-bottom: 0.24rem;
      text-align: center;

      span {
        font-size: 0.18rem;
        line-height: 0.288rem;
      }
    }

    &-btn {
      width: 1.75rem;
      height: 0.5rem;
      border-radius: 0.65rem;
      background-color: rgba(0, 12, 0, 0.9);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.14rem;
      line-height: 0.224rem;
      font-family: 'NexaHeavy';
      margin: 0 auto;
      color: #fff;
      cursor: pointer;

      img {
        display: block;
        width: 0.16rem;
        height: 0.16rem;
        margin-right: 0.08rem;
      }
    }

    &-boll {
      width: 100%;
      margin-top: -0.5rem;
      // position: absolute;
      // left: -0.1rem;
      // top: 2.52rem;
      // left: 0;
      // width: 4.1642rem;
      // height: 5.55rem;
    }
  }

  &-video {
    margin-top: -0.4rem;
    position: relative;
    z-index: 1;
    padding: 0.2rem;
    margin-bottom: 0.8rem;

    &-wrap {
      width: 100%;
      height: 1.9rem;
      border-radius: 0.32rem;
      background-color: #ffffff;
      overflow: hidden;
    }
  }

  &-features {
    position: relative;
    z-index: 1;
    padding-top: 0.8rem;
    margin-bottom: 0.4rem;

    ::-webkit-scrollbar {
      display: none;
    }

    &-title {
      font-size: 0.32rem;
      line-height: 0.48rem;
      font-family: 'NexaHeavy';
      color: #000;
      margin-bottom: 0.39rem;
      text-align: center;
    }

    &-infos {
      width: 100%;
      height: 4.23rem;
      overflow: hidden;

      &-wrap {
        height: 4.23rem;
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;

        &-item {
          width: 2.77rem;
          height: 4.23rem;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 0.2rem;
          padding: 0.12rem 0.2rem 0 0.2rem;
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          margin-right: 0.2rem;
          display: inline-block;
          overflow: hidden;

          &:first-child {
            margin-left: 0.41rem;
          }

          &:last-child {
            margin-right: 0.41rem;
          }

          &-icon {
            width: 0.8rem;
            height: 0.8rem;
            display: block;
            margin-bottom: 0.15rem;
          }

          &-title {
            font-size: 0.16rem;
            line-height: 0.19rem;
            color: #000000;
            font-weight: 900;
            margin-bottom: 0.08rem;
          }

          &-desc {
            font-weight: 400;
            font-size: 0.14rem;
            line-height: 0.19rem;
            color: rgba(0, 0, 0, 0.6);
            white-space: normal;
          }
        }
      }
    }
  }
}
