.mobile-footer {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 1.33rem;
  padding-left: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &-logo {
    width: 1.43rem;
    height: 0.27rem;
    display: block;
    margin-bottom: 0.14rem;
  }

  &-right {
    font-size: 0.14rem;
    line-height: 0.19rem;
    font-weight: 400;
    color: #000;
  }
}
