.cases {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  &-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-wrap {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    width: 5rem;
    padding: 1.52rem 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-title {
    font-size: 0.32rem;
    line-height: 0.46rem;
    margin-bottom: 0.04rem;
    color: #000;
    font-family: 'NexaHeavy';
  }

  &-sub {
    font-size: 0.16rem;
    line-height: 0.23rem;
    margin-bottom: 0.24rem;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
  }

  &-infos {
    width: 8.78rem;
    height: 3.42rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.7332rem;

    &-item {
      width: 2.81rem;
      height: 3.42rem;
      // background-image: url('../../assets/images/cases_container.png');
      // background-size: 100%;
      // background-position: top left;
      // background-repeat: no-repeat;
      position: relative;
      padding: 0.28rem 0.23rem 0;
      border-radius: 0.2rem;
      background-color: rgba(255, 255, 255, 0.2);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);

      &-no {
        position: absolute;
        top: 0;
        right: -0.037rem;
        width: 0.5175rem;
        height: 0.5175rem;
        border-radius: 100%;
        background-color: #121212;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.1922rem;
        color: #fff;
        font-family: 'RRocgrotesk';
      }

      &-dot {
        width: 0.54rem;
        height: 0.54rem;
        border-radius: 100%;
        background-color: rgba(119, 173, 255, 0.19);
        margin-bottom: 0.28rem;
      }

      &-title {
        font-size: 0.19rem;
        line-height: 0.26rem;
        color: #000000;
        font-weight: 900;
      }

      &-sub {
        font-size: 0.16rem;
        line-height: 0.21rem;
        color: #000000;
        font-weight: 500;
        font-style: italic;
        margin-bottom: 0.15rem;
      }

      &-content {
        font-weight: 400;
        font-size: 0.15rem;
        line-height: 0.2rem;
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}
