.header {
  width: 8.78rem;
  height: 0.53rem;
  border-radius: 0.51rem;
  background-color: rgba(255, 255, 255, 0.38);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: fixed;
  left: 50%;
  top: 0.49rem;
  transform: translateX(-50%);
  padding-left: 0.4rem;
  padding-right: 0.0398rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;

  &-logo {
    width: 1.28rem;
    height: 0.24rem;
    display: block;
  }

  &-nav {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;

      &:nth-child(2) {
        margin: 0 0.4rem;
      }
    }

    &-item {
      font-size: 0.12rem;
      color: #000;
      font-family: 'NexaBlod';

      &.active {
        color: #000000;
      }
    }

    &-demo {
      width: 1.09rem;
      height: 0.42rem;
      border-radius: 0.51rem;
      background-color: rgba(255, 255, 255, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.12rem;
      color: #000000;
      cursor: pointer;
      font-family: 'NexaBlod';

      &:hover {
        background-color: rgba(0, 0, 0, 0.7);
        color: rgba(249, 250, 254, 1);
        transform: scale(1);
      }

      img {
        width: 0.0904rem;
        height: 0.0944rem;
        display: block;
        margin-left: 0.1rem;
      }
    }
  }
}
